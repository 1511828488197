const structuredData = {
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "Deeple",
  alternateName: "ดีเปิ้ล",
  url: "https://deeple.ai",
  logo: "https://console.deeple.ai/assets/logo/deeple-logo-black.png",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "02-460-9595",
    contactType: "customer service",
    areaServed: "TH",
    email: "contact@deeple.ai",
    availableLanguage: ["Thai", "en"],
  },
  sameAs: [
    "https://www.facebook.com/deeplethailand",
    "https://www.youtube.com/deeplethailand",
    "https://www.linkedin.com/company/deepleai",
  ],
};

export default structuredData;
