import React from "react";
import "./index.css";

import FacebookIcon from "../../../static/icon/Facebook.svg";
import InstagramIcon from "../../../static/icon/Instagram.svg";
import YoutubeIcon from "../../../static/icon/Youtube.svg";

type FooterPropsType = {
  className?: string;
};

const Footer: React.FC<FooterPropsType> = () => {
  return (
    <div className="footer-container py-16 md:py-24">
      <div className="guides px-0">
        <div className="w-layout-grid guides-container">
          <div className="guide-line-1"></div>
          <div className="guide-line-2"></div>
          <div className="guide-line-3"></div>
          <div className="guide-line-4"></div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row relative pb-2">
        <div className="w-full md:w-1/2 order-2 md:order-1 px-4">
          <div className="flex flex-col pb-10">
            <div className="font-graphikthloop font-semibold pb-2 text-center md:text-left">
              deeple Co,Ltd. (Head Office)
            </div>
            <div className="font-graphikthloop text-center md:text-left">
              173 Asia Center Bldg.
            </div>
            <div className="font-graphikthloop text-center md:text-left">
              28 Fl. South Sathorn Rd,
            </div>
            <div className="font-graphikthloop text-center md:text-left">
              Thung Maha Mek, Sathorn
            </div>
            <div className="font-graphikthloop text-center md:text-left">
              Bangkok 10120
            </div>
          </div>
          <div className="flex justify-center md:justify-start items-center pb-10 gap-8">
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/deeplethailand"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-8" alt="Instagram" src={InstagramIcon} />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/deeplethailand"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-8" alt="Facebook" src={FacebookIcon} />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.youtube.com/deeplethailand"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-8" src={YoutubeIcon} alt="Youtube" />
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/2 order-1 md:order-2 flex flex-row">
          <div className="flex flex-col w-1/2 px-4">
            <a
              href="/"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Home
            </a>
            <a
              href="/blog"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Blog
            </a>
            <a
              href="/contact"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Contact Sales
            </a>
            <a
              href="https://tutorials.deeple.ai/"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Tutorials
            </a>
          </div>
          <div className="flex flex-col w-1/2 px-4">
            <a
              href="/pricing"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Pricing
            </a>
            <a
              href="/privacy"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Privacy Policy
            </a>
            <a
              href="/term-and-service"
              className="cursor-pointer font-graphikthloop pb-8 text-center md:text-left"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="font-graphikthloop text-sm">
          © 2022 deeple Co., Ltd. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
